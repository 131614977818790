import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  HostListener
} from '@angular/core';

import {
  Platform,
  NavController,
  PopoverController,
  MenuController
} from '@ionic/angular';
import { Store } from '@ngrx/store';

import { environment } from '@environment';
import * as fromApp from '@store/app.reducer';
import * as authActions from '@store/auth/auth.actions';
import { Observable, Subscription, timer } from 'rxjs';
import { filter, distinct, map, buffer, debounceTime } from 'rxjs/operators';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  ActivationEnd
} from '@angular/router';
import { TranslationService } from '@core/services/translation/translation.service';
import { SharedService } from './shared/shared.service';
import { ContactsComponent } from '@shared/components/contacts/contacts.component';
import { TicketBubleHostDirective } from './ticket-buble/ticket-buble-host.directive';
import { TicketBubleService } from '@core/services/ticket-buble/ticket-buble.service';
import * as moment from 'moment';
import { User } from '@models/User';
import {
  authSelectors,
  bonusesSelectors,
  maintenanceSelectors
} from '@store/selectors';
import { CookieService } from 'ngx-cookie-service';
import { bonusesActions } from '@store/actions';

import { NoticeSettup } from '@shared/components/notice/notice.model';
import { NoticeService } from '@shared/components/notice/notice.service';
import { TranslateService } from '@ngx-translate/core';
import { translateRoute } from '@core/functions/translateRoute';
import { ProfileService } from '@core/services/profile/profile.service';
import { AuthService } from '@core/services/auth/auth.service';
import { KNOWN_BONUSES } from 'src/constants/knownBonuses';
import { configuration } from '@configuration';
import { Settings } from '@models/Settings';
import { COOKIES_SETTINGS_PATH } from '@constants';
import { GlobalService } from '@shared/global.service';
import { Actions, ofType } from '@ngrx/effects';
import Clarity from '@microsoft/clarity';

export const signalrHubSettings = {
  hubName: 'offerHub',
  url: environment.hubConnection,
  options: {
    skipNegotiation: true,
    transport: 1
  }
};

export const timeoutSettings = {
  tickerSec: 1000,
  balanceRefreshSec: 30000
};

// export const signalrHub = createHub(signalrHubSettings.hubName, signalrHubSettings.url);

export interface CasinoPoints {
  CanBePaid: boolean;
  Points: number;
}

@Component({
  selector: 'doxx-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  BUILD_INFORMATION = BUILD_INFORMATION;
  COOKIES_SETTINGS_PATH = COOKIES_SETTINGS_PATH;
  selectIsWalletBlocked$: Observable<boolean>;

  private _ticker: Subscription;
  private _balanceRefreshInterval: Subscription;

  constructor(
    private _menuController: MenuController,
    private _platform: Platform,
    private _store: Store<fromApp.AppState>,
    private _router: Router,
    private _translationService: TranslationService,
    private _translateService: TranslateService,
    private _navCtrl: NavController,
    private _sharedService: SharedService,
    private _ticketBubleService: TicketBubleService,
    private _popoverCtrl: PopoverController,
    private _cookieService: CookieService,
    private _noticeService: NoticeService,
    private _profileServce: ProfileService,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _actions$: Actions
  ) {
    this._initializeApp();
  }
  private _subscriptions$ = new Subscription();
  public loginDiffTime = new Date();
  public casinoPoints: number;
  public userData: User;
  public operatingSystem: string;
  public isClientLoggedIn$: Observable<boolean>;
  public userSettings$: Observable<Settings> = this._store.select(
    authSelectors.selectSettings
  );
  public casinoPoints$: Observable<CasinoPoints>;
  @ViewChild(TicketBubleHostDirective)
  public ticketBubleHostDirective: TicketBubleHostDirective;
  public notice: NoticeSettup = null;
  public shouldShowAppModal = undefined;
  public isAcceptsCookies = undefined;
  public tempHideCookies = undefined;
  public appType: 'betting' | 'casino' = 'betting';

  public profilePicutre = '';
  public isProfilePicutre = false;

  public KNOWN_BONUSES = KNOWN_BONUSES;

  menu = configuration.menu;
  contact = configuration.contact;
  configuration = configuration;
  otherPlatforms = configuration.otherPlatforms;
  location = environment.location;

  /**
   * Get operation system - Old resonsive web app implementation
   */
  private _getOperatingSystemOld(): string {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    let operatingsystem: string;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      operatingsystem = 'winphone';
      // Not implemented
    } else if (/android/i.test(userAgent)) {
      operatingsystem = 'android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      operatingsystem = 'ios';
    } else {
      operatingsystem = 'desktop';
    }
    return operatingsystem;
  }

  /** skryje modal s vyzvou pre instalovanie appky */
  acceptCookies(): void {
    this.isAcceptsCookies = true;
    this._cookieService.set(
      environment.cookies.acceptCookies.key,
      'true',
      +environment.cookies.acceptCookies.expiration,
      '/'
    );
    window.dispatchEvent(new CustomEvent('consentGranted'));
  }

  /**
   * Initialize web application
   */
  private _initializeApp(): void {
    const showAppDownloadModal = this._cookieService.get(
      environment.cookies.appModal.key
    );
    if (showAppDownloadModal) {
      this.shouldShowAppModal = showAppDownloadModal === 'true';
    }

    const showCookiesModal = this._cookieService.get(
      environment.cookies.acceptCookies.key
    );
    if (showCookiesModal) {
      this.isAcceptsCookies = showCookiesModal === 'true';
    }
    // INIT clarity
    const projectId = 'pcxpyws9w1';

    Clarity.init(projectId);

    this._platform.ready().then(() => {
      // this.operatingSystem = this._getOperatingSystem();
      this.operatingSystem = this._getOperatingSystemOld();
      this._sharedService.operatingSystem = this.operatingSystem;
    });

    // dispatch autologin
    this._store.dispatch(authActions.autologinStart());

    // reads fav events from cookie
    // this._store.dispatch(new authActions.FavEventsReadStart());

    // this._store.dispatch(new authActions.GetFavouritesStart());

    // select if user is logged in
    this.isClientLoggedIn$ = this._store.select(
      authSelectors.selectIsUserLoggedIn
    );

    this._subscriptions$.add(
      this._actions$
        .pipe(ofType(authActions.loginSuccess, authActions.autologinSuccess))
        .subscribe(action => {
          // Ak je uzivatel prihlaseny
          if (
            action.type === '[Auth] AutoLogin Success' &&
            this._cookieService.check('LoginTime')
          ) {
            // Vytiahne cookie s casom prihlasenia (Uzivatel uz bol prihlaseny)
            const loginTime = this._cookieService.get('LoginTime');
            // Nastavi ako dlho je prihlaseny
            const loginSecondsDiff = new Date(+loginTime).secondsDiff(
              new Date()
            );
            this.loginDiffTime = new Date(
              new Date().setHours(0, 0, 0, 0)
            ).addSeconds(loginSecondsDiff);
          } else {
            // Nastavi cookie s aktualnym casom (Uzivatel sa aktualne prihlasil)
            this.loginDiffTime = new Date(new Date().setHours(0, 0, 0, 0));
            this._cookieService.set(
              'LoginTime',
              new Date().getTime().toString()
            );
          }
          // Zapne ticker pocitadlo casu prihlasenia
          this._ticker = timer(0, timeoutSettings.tickerSec).subscribe(
            () => (this.loginDiffTime = this.loginDiffTime.addSeconds(1))
          );

          this._balanceRefreshInterval = timer(
            0,
            timeoutSettings.balanceRefreshSec
          ).subscribe(() =>
            this._store.dispatch(authActions.balanceRefreshStart())
          );

          // Nacita casino body
          this.getCasinoPoints();

          // Nacita profilovu fotku
          this.getProfilePhoto();
        })
    );

    this._actions$
      .pipe(ofType(authActions.logoutSuccess, authActions.softLogoutSuccess))
      .subscribe(() => {
        // Odhlaseny
        // Vypne ticker pocitadlo casu prihlasenia
        this._ticker?.unsubscribe();

        // Vypne refresh balance
        this._balanceRefreshInterval?.unsubscribe();
      });

    // SignalrService();
    // this._store.dispatch(createSignalRHub(signalrHubSettings));
    // this._store.dispatch(startSignalRHub(signalrHubSettings));

    // const signalrHub = new signalR.HubConnectionBuilder().withUrl(environment.signalrHubUrl, {
    //   skipNegotiation: true,
    //   transport: signalR.HttpTransportType.WebSockets
    // }).withAutomaticReconnect().build();

    // Register 'Odd Change' Event
    // $rootScope.signalRConnection.on('OddsChanged', function (oddsID, oddsRate, oddStatus) {
    //     // console.info('oddsID: ' + oddsID + ' | oddsRate:' + oddsRate);
    //     $rootScope.$broadcast('signalr_OddsChanged', {'oddsID': oddsID, 'oddsRate':oddsRate, 'status': oddStatus});
    // });

    // // Register 'Event ChanceType Change' Event
    // $rootScope.signalRConnection.on('EventChanceTypeChanged', function (eventChanceTypeID, status, eventID) {
    //     // console.info('eventChanceTypeChanged: ' + eventChanceTypeID + ' | status:' + status + ' | eventID:' + eventID);
    //     $rootScope.$broadcast('signalr_EventChanceTypeChanged', {'eventChanceTypeID': eventChanceTypeID,
    // 'status':status, 'eventID':eventID});
    // });

    // // Register 'Event Change' Event
    // $rootScope.signalRConnection.on('EventChanged', function (eventID, actualGamePartID, actualGamePartTime, score, liveBettingView,
    // eventDate)
    // {
    //     $rootScope.$broadcast('signalr_EventChanged', {'eventID': eventID, 'actualGamePartID':actualGamePartID,
    // 'actualGamePartTime':actualGamePartTime, 'score':score, 'liveBettingView':liveBettingView, 'eventDate':eventDate});
    // });

    // $rootScope.signalRConnection.on('JackpotChanged', function (provider, amount) {
    //     $rootScope.$broadcast('signalr_JackpotChanged', {'provider': provider, 'amount':amount});
    // });

    // $rootScope.signalRConnection.on('StreamAdded', function (raceId, streamUrl) {
    //     $rootScope.$broadcast('signalr_StreamAdded', {'raceId': raceId, 'streamUrl':streamUrl});
    // });

    // $rootScope.signalRConnection.on('OddsCreated', function (eventID) {
    //     $rootScope.$broadcast('signalr_OddsCreated', {'eventID': eventID});
    // });

    // $rootScope.signalRConnection.start(function () {
    //     $rootScope.signalRReady = false;
    // }).then(function () {
    //     $rootScope.signalRReady = true;
    //     if ($rootScope.signalRWaitingView) {
    //         $rootScope.signalRConnection.invoke("ChangeView", $rootScope.signalRWaitingView, $rootScope.previousSignalRView);
    //         $rootScope.previousSignalRView = $rootScope.signalRWaitingView;
    //     }
    //     // $rootScope.batchChangesInterval = $interval($rootScope.applyAllBatchChanges, 300, false);
    // });
  }

  /**
   * Casino points
   */
  getCasinoPoints(): void {
    this._store.dispatch(bonusesActions.loadLoyalityPoints());
    this.casinoPoints$ = this._store
      .select(bonusesSelectors.selectLoyality)
      .pipe(filter(loyality => Boolean(loyality)));
    this._subscriptions$.add(
      this.casinoPoints$.subscribe(data => {
        this.casinoPoints = data.Points;
      })
    );
  }

  /**
   * Initialisation AppComponent
   */
  ngOnInit(): void {
    moment.locale(environment.language);

    this._globalService.showCookiesBanner.subscribe(change => {
      this.tempHideCookies = change;
    });

    this.selectIsWalletBlocked$ = this._store.select(
      maintenanceSelectors.selectIsWalletBlocked
    );
    // Check affiliate ID
    if (
      window.location.search.includes('a_aid=') ||
      window.location.search.includes('a_bid=') ||
      window.location.search.includes('chan=')
    ) {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const a_aid = url.searchParams.get('a_aid');
      const a_bid = url.searchParams.get('a_bid');
      const chan = url.searchParams.get('chan');
      this.setAffiliateParams(a_aid, a_bid, chan);
    }

    // Navigacia spat, pocuva na kliknutie tlacidla spat
    this._subscriptions$.add(
      this._sharedService.getNavigation().subscribe((data: string) => {
        if (data === 'back') {
          this._navCtrl.back();
        }
      })
    );

    // Emituje zmenu url cez service
    this._subscriptions$.add(
      this._router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          distinct()
        )
        .subscribe((event: NavigationEnd) => {
          this._sharedService.changeRoute(event.url.split('/'));
        })
    );
    // Emituje zmenu url cez service
    this._subscriptions$.add(
      this._router.events
        .pipe(
          filter(e => e instanceof ActivationEnd),
          buffer(
            this._router.events.pipe(
              filter(e => e instanceof NavigationEnd),
              debounceTime(0)
            )
          ),
          map((events: ActivationEnd[]) =>
            events.reduce(
              (acc, curr) => ({ ...acc, ...curr.snapshot.data }),
              {}
            )
          )
        )
        .subscribe((routerData: any) => {
          if (routerData.downloadCasinoApp) {
            this.appType = 'casino';
          } else {
            this.appType = 'betting';
          }
        })
    );
    this.getUserData();
  }

  /** We need to set reference on host directive to ticket buble service */
  ngAfterViewInit(): void {
    this._ticketBubleService.ticketBubleHostDirective =
      this.ticketBubleHostDirective;
  }

  /**
   * Get User Data
   */
  getUserData(): void {
    this._subscriptions$.add(
      this._store.select(authSelectors.selectLoggedUser).subscribe(data => {
        this.notice = null;
        if (data) {
          this.userData = data;
          if (configuration.verification.allowed && !this.userData.verified) {
            this.notice = {
              context: 'userNotVerfied',
              icon: 'alert1',
              customIcon: true,
              iconBackground: '#E2C241',
              background: '#F7F2E8',
              header: this._translateService.instant(
                'identity_verification_general_msg_11'
              ),
              text: this._translateService.instant(
                'identity_verification_general_msg_6'
              ),
              buttonText: this._translateService.instant(
                'identity_verification_general_msg_10'
              )
            };
            this._noticeService.noticeButton.subscribe((context: string) => {
              if (context === 'userNotVerfied') {
                this.closeMenu();
                this._router.navigate([translateRoute('VerificationsList')]);
              }
            });
          }
        } else {
          this.userData = null;
        }
      })
    );
  }
  /**
   * get profile picture
   */
  getProfilePhoto(): void {
    this._profileServce
      .getProfileImage()
      .toPromise()
      .then((data: any) => {
        this.profilePicutre = 'data:image/png;base64,' + data;
        this.isProfilePicutre = true;
      })
      .catch(data => {
        // console.log(data);
      });
  }

  /**
   * get bocking info with allfiliate params
   */
  setAffiliateParams(a_aid?, a_bid?, chan?): void {
    ///////
    let url = '?';
    if (a_aid) {
      url = url + 'a_aid=' + a_aid;
      if (a_bid || chan) {
        url = url + '&';
      }
    }
    if (a_bid) {
      url = url + 'a_bid=' + a_bid;
      if (chan) {
        url = url + '&';
      }
    }
    if (chan) {
      url = url + 'chan=' + chan;
    }
    this._profileServce
      .getAffiliateData(url)
      .toPromise()
      .catch(data => {
        //  console.log(data);
      });

    if (a_aid) {
      this._cookieService.set('a_aid', a_aid, 60);
    }
    if (a_bid) {
      this._cookieService.set('a_bid', a_bid, 60);
    }
    if (chan) {
      this._cookieService.set('chan', chan, 60);
    }
  }

  /**
   * Handle logout
   */
  onLogout(): void {
    this._store.dispatch(authActions.logoutStart());
  }
  /**
   * onAtilla
   */
  onAtilla(): void {
    // eslint-disable-next-line no-console
    console.log(
      '%c+',
      'font-size: 1px; padding: 60px 320px; line-height: 1px; background: url(https://m.doxxbet.sk/assets/images/klikuj.gif); background-size: 640px 122px; color: transparent;'
    );
  }

  /**
   * Sets new language
   */
  onLangToggle(): void {
    const newLanguage = environment.language === 'sk' ? 'en' : 'sk';
    this._translationService.setTranslation(newLanguage);
    moment.locale(newLanguage);
  }

  /**
   * present contacts popover
   *
   * @param ev $event
   */
  async presentContactsPopover(ev?: any): Promise<void> {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const popover = await this._popoverCtrl.create({
      component: ContactsComponent,
      cssClass: 'contacts-popover',
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  /**
   * Close menu when item in menu is clicked
   */
  closeMenu(): void {
    this._menuController.close();
  }

  /**
   * On destroy unsubscribe
   */
  ngOnDestroy(): void {
    this._subscriptions$.unsubscribe();
  }

  /**
   * Zisti ci ma stiahnut kasino alebo betting appku a presmeruje pouzivatela do storu
   */
  downloadIosApp(): void {
    if (this.appType === 'casino') {
      window.open(this.otherPlatforms.iosCasino.link, '_blank');
    } else {
      window.open(this.otherPlatforms.ios.link, '_blank');
    }
  }

  /**
   * Zisti ci ma vypnut button na stiahnutie kasino alebo betting appky
   */
  disableDownloadIosApp(): boolean {
    if (this.appType === 'casino') {
      return !this.otherPlatforms.iosCasino.allowed;
    } else {
      return !this.otherPlatforms.ios.allowed;
    }
  }

  /**
   * setting koliecko sa bude zobrazovat na zaklade podmineky ak existuje
   * pre dany config viac jazykov alebo mien alebo casovych zon, tak sa
   * koliecko v menu zobrazi
   *
   * TODO viac casovych zon
   */
  showSettingsWheel(): boolean {
    return (
      (configuration.settings.language &&
        configuration.base.languages.length > 1) ||
      (configuration.settings.currency &&
        configuration.base.currencies.length > 1)
    );
  }

  trackMenuItem = (item, index) => `${index}${item.destination}`;
}
